//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'
import hasMenu from '~/mixins/hasMenu'
import IconFacebook from '~/assets/svg/facebookOrangeLogoCharm.svg?inline'
import IconInstagram from '~/assets/svg/instagramOrangeLogoCharm.svg?inline'
import IconTwiter from '~/assets/svg/twitterOrangeLogoCharm.svg?inline'
import IconTiktok from '~/assets/svg/tiktokOrangeLogoCharm.svg?inline'
import IconYoutube from '~/assets/svg/youtubeOrangeLogoCharm.svg?inline'

export default {
  components: {
    IconFacebook,
    IconInstagram,
    IconTwiter,
    IconTiktok,
    IconYoutube,
  },
  mixins: [hasMenu],

  computed: {
    year() {
      return dayjs().year()
    },
  },
}
