import get from 'lodash/get'

class Alert {
  constructor(context) {
    this._context = context
  }

  get items() {
    return this._context.store.state.alert.items
  }

  /**
   * Confirm.
   *
   * @param {String} type Theme.
   * @param {String} message Message or object data.
   * @param {Object} options
   */
  alert(type, message, options = {}) {
    const data = {
      type,
      message,
      options,
    }

    this._context.store.commit('alert/add', data)
  }

  /**
   * Alert for request success.
   *
   * @param {AxiosResponse} response
   * @param {Object} options
   */
  requestSuccess(response, options) {
    const message = get(response, 'data.message')
    this.alert('success', message, options)
  }

  /**
   * Alert for request error.
   *
   * @param {Error} error
   * @param {Object} options
   */
  requestError(error, options = { showPrevModal: true }) {
    let message = get(error, 'response.data.message') || error.message

    if (get(error, 'response.status') === 422) {
      const validation = get(error, 'response.data.errors', {})
      message = Object.values(validation)[0][0]
    }

    this.alert('danger', message, options)
  }

  /**
   * Remove an item.
   *
   * @param {Number} index
   */
  remove(index) {
    this._context.store.commit('alert/remove', index)
  }
}

export default (context, inject) => {
  const $alert = new Alert(context)
  inject('alert', $alert)
}
