import Model from '@fahrirusliyadi/vuejs-model'
import slugify from '@sindresorhus/slugify'
import { generateImageURL } from '../utils/storage'

export default class Category extends Model {
  static get TYPE_ARTICLE() {
    return 'ARTICLE'
  }

  static get TYPE_PRODUCT() {
    return 'PRODUCT'
  }

  static get TYPE_VIDEO() {
    return 'VIDEO'
  }

  static get TYPE_FAQ() {
    return 'FAQ'
  }

  get $attributes() {
    return [
      'id',
      'type',
      'name',
      'description',
      'image',
      'banner',
      'created_at',
      'updated_at',
    ]
  }

  get bannerURL() {
    return this.banner && generateImageURL(this.banner)
  }

  get imageURL() {
    return this.image && generateImageURL(this.image)
  }

  get routePath() {
    switch (this.type) {
      case this.constructor.TYPE_ARTICLE:
        return `/artikel/kategori/${this.id}/${this.slug}`
      case this.constructor.TYPE_PRODUCT:
        return `/produk/kategori/${this.id}/${this.slug}`
      case this.constructor.TYPE_VIDEO:
        return `/video/kategori/${this.id}/${this.slug}`
    }
  }

  get slug() {
    return slugify(this.name)
  }

  get theme() {
    const themes = [
      'primary',
      'primary-blue',
      'info',
      'success',
      'warning',
      'danger',
    ]

    return themes[(this.id - 1) % themes.length]
  }
}
