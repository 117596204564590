class ModalCompleteData {
  constructor(context) {
    this._context = context
  }

  get showCompleteData() {
    return this?._context.store?.state?.modal?.showCompleteData
  }

  /**
   * Show.
   *
   */
  show() {
    this._context.store.commit('modal/setShowCompleteData', true)
  }

  /**
   * Hide.
   *
   */
  hide() {
    this._context.store.commit('modal/setShowCompleteData', false)
  }
}

export default (context, inject) => {
  const $modalCompleteData = new ModalCompleteData(context)
  inject('modalCompleteData', $modalCompleteData)
}
