import { render, staticRenderFns } from "./NavbarDesktop.vue?vue&type=template&id=37a1d91a&scoped=true&"
import script from "./NavbarDesktop.vue?vue&type=script&lang=js&"
export * from "./NavbarDesktop.vue?vue&type=script&lang=js&"
import style0 from "./NavbarDesktop.vue?vue&type=style&index=0&id=37a1d91a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a1d91a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/var/www/charm-app/components/user/UserAvatar.vue').default,AuthModalRegister: require('/var/www/charm-app/components/auth/AuthModalRegister.vue').default,AuthModalLogin: require('/var/www/charm-app/components/auth/AuthModalLogin.vue').default,UserFormModal: require('/var/www/charm-app/components/user/UserFormModal.vue').default,UserFormPasswordModal: require('/var/www/charm-app/components/user/UserFormPasswordModal.vue').default})
