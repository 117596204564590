export default async function ({
  $auth,
  redirect,
  route,
  $modalCompleteData,
  from,
  app,
}) {
  const handle = () => {
    if (!$auth.isLoggedIn) {
      redirect(`/auth?redirectTo=${route.fullPath}`)
    } else if ($auth.hasProviderId) {
      if (process.server) {
        app.$cookiz.set('show_complete_data', '1', {
          path: '/',
        })
      }

      if (from) {
        if (from.name === 'auth-social-callback') {
          redirect('/auth')
        } else {
          localStorage.setItem('redirectTo', route.fullPath)
          redirect(from.fullPath)
        }
      } else {
        redirect('/')
      }

      if (process.client) {
        setTimeout(() => {
          $modalCompleteData.show()
        }, 50)
      }
    }
  }

  if ($auth.isLoading) {
    await new Promise((resolve) =>
      $auth.once('loaded', () => resolve(handle()))
    )
  } else {
    handle()
  }
}
