//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '~/mixins/hasWindow'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'
import AuthLogin from '~/components/auth/AuthLogin.vue'

export default {
  components: { AuthLogin },
  extends: BootstrapModal,
  mixins: [hasWindow],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'modal-above-navbar-drawer'],
    },
    refModal: {
      type: Object,
    },
  },

  data() {
    return {
      showPassword: false,
      password: null,
    }
  },

  methods: {
    handleRegisterModal() {
      this.refModal.authModalRegister.modal.show()
    },
    handleSocialLogin(type) {
      this.$auth.loginWithSocial(type)
    },
    hideModal() {
      this.modal.hide()
    },
  },
  //
  // data() {
  //   password: "",
  //   passwordFieldType: "password"
  // },
}
