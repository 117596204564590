//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasMenu from '~/mixins/hasMenu'

import IconDownward from '~/assets/svg/downward.svg?inline'
import IconUpward from '~/assets/svg/upward.svg?inline'

export default {
  components: { IconDownward, IconUpward },

  mixins: [hasMenu],

  data() {
    return { isUserMenuDisplayed: false }
  },

  computed: {
    isNewPassword() {
      return Boolean(this?.$auth?.user?.is_new_password)
    },
  },

  watch: {
    isMenuDrawerDisplayed(isMenuDrawerDisplayed) {
      document.body.classList.toggle(
        'menu-drawer-displayed',
        isMenuDrawerDisplayed
      )
    },
  },

  mounted() {
    document.body.appendChild(this.$refs.authModalLogin.$el)
    document.body.appendChild(this.$refs.authModalRegister.$el)
    document.body.appendChild(this.$refs.userFormModal.$el)
    document.body.appendChild(this.$refs.userFormPasswordModal.$el)
    if (this.$route.query.login) {
      this.$refs.authModalLogin.modal.show()
    }
  },

  beforeDestroy() {
    this.$el.appendChild(this.$refs.authModalLogin.$el)
    this.$el.appendChild(this.$refs.authModalRegister.$el)
    this.$el.appendChild(this.$refs.userFormModal.$el)
    this.$el.appendChild(this.$refs.userFormPasswordModal.$el)
  },

  methods: {
    showDropdownNav(index) {
      const navDropdown = document.querySelectorAll(
        '.nav-dropdown-link-wrapper'
      )
      const element = document.getElementById(
        `nav-dropdown-link-wrapper-${index}`
      )
      const parentElement = document.getElementById(
        `nav-dropdown-item-${index}`
      )

      if (element.classList.contains('active')) {
        navDropdown.forEach((item) => {
          item.classList.remove('active')
          parentElement.classList.remove('active')
        })
      } else {
        element.classList.add('active')
        parentElement.classList.add('active')
      }
    },
  },
}
