import Model from '@fahrirusliyadi/vuejs-model'
import { generateImageURL } from '../utils/storage'

export default class Inspiration extends Model {
  static TYPE_EXAMPLE = 'EXAMPLE'
  static TYPE_INSPIRATION = 'INSPIRATION'

  get $attributes() {
    return [
      'id',
      'user_id',
      'type',
      'inspirator',
      'content',
      'image',
      'created_at',
      'updated_at',
      'custom_author',
      'user',
    ]
  }

  get authorName() {
    return this.custom_author || this.user.name;
  }

  get imageURL() {
    if (this.image.startsWith('/_nuxt/')) return this.image
    return generateImageURL(this.image)
  }
}
