// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/pretest/bg.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/pretest/bg-2x.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/pretest/bg-md.jpg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/img/pretest/bg-md-2x.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "main[data-v-13cd3842]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/cover}@media only screen and (min-width:768px)and (-webkit-min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-resolution:2dppx),only screen and (min-width:768px)and (min-resolution:192dpi){main[data-v-13cd3842]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}@media(min-width:768px){main[data-v-13cd3842]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}@media only screen and (min-width:768px)and (-webkit-min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-resolution:2dppx),only screen and (min-width:768px)and (min-resolution:192dpi){main[data-v-13cd3842]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}}.container[data-v-13cd3842]{max-width:30.75rem}", ""]);
// Exports
module.exports = exports;
