export default async function ({ $auth, redirect }) {
  const handle = () => {
    if ($auth.isLoggedIn) {
      redirect('/')
    }
  }

  if ($auth.isLoading) {
    await new Promise((resolve) =>
      $auth.once('loaded', () => resolve(handle()))
    )
  } else {
    handle()
  }
}
