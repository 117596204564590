//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '~/mixins/hasWindow'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'

export default {
  name: 'ModalSurvey',
  extends: BootstrapModal,
  mixins: [hasWindow],

  props: {
    bodyClass: {
      type: Array,
      default: () => [],
    },
    refModal: {
      type: Object,
    },
    email: String,
    modalData: {
      title: String,
      subtitle: String,
    },
  },
  methods: {
    handleLoginModal() {
      this.refModal.authModalLogin.modal.show()
    },
    handleSubmit() {
      this.refModal.authModalRegister.modal.show()
    },
  },
}
