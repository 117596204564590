//
//
//
//
//

import SwapGoldPointSuccess from '~/components/modal/SwapGoldPointSuccess'
export default {
  components: { SwapGoldPointSuccess },
}
