import Model from '@fahrirusliyadi/vuejs-model'
import { generateImageURL } from '../utils/storage'

/* eslint-disable accessor-pairs */

export default class Question extends Model {
  static get TYPE_MULTIPLE_CHOICE() {
    return 'MULTIPLE_CHOICE'
  }

  static get TYPE_TRUE_FALSE() {
    return 'TRUE_FALSE'
  }

  get $attributes() {
    return [
      'id',
      'quiz_id',
      'role_id',
      'type',
      'text',
      'image',
      'is_true',
      'created_at',
      'updated_at',
      'has_multiple_correct_choices',
      'choices',
      'role',
    ]
  }

  // eslint-disable-next-line camelcase
  set quiz_id(id) {
    this.$set('quiz_id', Number(id))
  }

  get imageURL() {
    return generateImageURL(this.image)
  }
}
