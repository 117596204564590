//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconEye from '~/assets/svg/eye.svg?inline'
import IconEyeInvisible from '~/assets/svg/eye-invisible.svg?inline'

export default {
  components: { IconEye, IconEyeInvisible },

  props: {
    attrs: { type: Object, default: () => ({}) },
    value: { type: String, default: '' },
  },

  data() {
    return { passwordVisible: false }
  },

  watch: {
    passwordVisible() {
      this.$el.querySelector('input').focus()
    },
  },
}
