import { render, staticRenderFns } from "./UserFormPasswordModal.vue?vue&type=template&id=0a9cee6e&"
import script from "./UserFormPasswordModal.vue?vue&type=script&lang=js&"
export * from "./UserFormPasswordModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldPassword: require('/var/www/charm-app/components/form/FormFieldPassword.vue').default,UserFormPasswordModalSuccess: require('/var/www/charm-app/components/user/UserFormPasswordModalSuccess.vue').default})
