export default {
  state: () => ({
    main: [
      { label: 'Artikel', path: '/artikel' },
      { label: 'Kelas Online', path: '/kelas-online' },
      { label: 'Produk', path: '/produk' },
      { label: 'Tanya Kami', path: '/tanya-kami' },
      { label: 'Aktivitas', path: '/galeri' },
      {
        label: 'Gold Points',
        path: '/gold-points',
        icon: '/images/icon-gold-point.svg',
        children: [
          { label: 'Trivia Quiz', path: '/gold-points/trivia' },
          { label: 'Gacha', path: '/gold-points/gacha' },
          { label: 'Rewards', path: '/gold-points/?tab=1' },
          { label: 'How To Get', path: '/gold-points/?tab=2' },
        ],
      },
    ],
    footer: [
      { label: 'Kelas Online', path: '/kelas-online' },
      { label: 'Produk', path: '/produk' },
      { label: 'Tanya Kami', path: '/tanya-kami' },
      { label: 'Aktivitas', path: '/galeri' },
      { label: 'Tentang', path: '/tentang' },
      { label: 'Gold Points', path: '/gold-points' },
    ],
    isDrawerDisplayed: false,
  }),

  mutations: {
    setIsDrawerDisplayed: (state, isDrawerDisplayed) =>
      (state.isDrawerDisplayed = isDrawerDisplayed),
  },
}
