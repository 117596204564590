import isHTTPS from 'is-https'

export default {
  state: () => ({ baseURL: null }),

  mutations: {
    setBaseURL: (state, baseURL) => (state.baseURL = baseURL),
  },

  actions: {
    nuxtServerInit({ commit }, { req }) {
      commit(
        'setBaseURL',
        `${isHTTPS(req) ? 'https' : 'http'}://${req.headers.host}`
      )
    },
  },
}
