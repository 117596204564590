//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '~/mixins/hasWindow'
import FooterMobile from '~/components/FooterMobile'
import FloatingGachaIcon from '~/components/icons/FloatingGachaIcon'
import NavbarDesktop from '~/components/NavbarDesktop'

export default {
  components: {
    FooterMobile,
    FloatingGachaIcon,
    NavbarDesktop,
  },
  mixins: [hasWindow],

  data() {
    return {
      visible: false,
      email: '',
      showFooter: true,
      modalData: {
        title: 'Mau kemana nih?',
        subtitle:
          'Untuk informasi tentang menstruasi dan perempuan lebih lanjut, registrasi sekarang yuk!',
      },
    }
  },
  watch: {
    '$route.query'() {
      this.handleGetPointModal()
    },
    $route(to, from) {
      if (
        to.fullPath === '/gold-point/gacha' ||
        to.fullPath === '/gold-point/trivia'
      ) {
        this.showFooter = false
      }
      this.showFooter = true
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener)
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
    window.addEventListener('scroll', this.handleScroll)
    // document.body.addEventListener('mouseleave', this.handleRegistModal)
  },
  methods: {
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener(e) {
      this.visible = window.scrollY > 150
    },
    onidle() {
      alert('You have been logged out')
    },
    handleGetPointModal() {
      if (this.$auth.isLoggedIn) {
        if (
          this.$route.query['is-register'] === '1' &&
          !localStorage.getItem('isRegister')
        ) {
          if (this.$refs.registBeforeExit.$el.className.includes('show')) {
            this.$refs.registBeforeExit.modal.hide()
          }
          this.$modalGetPoint.show()
          localStorage.setItem('isRegister', 1)
        }
      }
    },
    handleRegistModal(type) {
      if (!this.$auth.isLoggedIn) {
        if (!this.$refs.registBeforeExit.$el.className.includes('show')) {
          this.modalData = {
            title: 'Mau kemana nih?',
            subtitle:
              'Untuk informasi tentang menstruasi dan perempuan lebih lanjut, registrasi sekarang yuk!',
          }

          if (type === 2) {
            this.modalData = {
              title: 'Yuk join sekarang!',
              subtitle:
                'Jadilah Charm Girls terupdate tentang menstruasi & perempuan sekarang!',
            }
          }

          this.$refs.registBeforeExit.modal.show()
        }
      }
    },
  },
}
