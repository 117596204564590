class ModalGetPoint {
  constructor(context) {
    this._context = context
  }

  get showGetPoint() {
    return this?._context.store?.state?.modal?.showGetPoint
  }

  show() {
    this._context.store.commit('modal/setShowGetPoint', true)
  }

  hide() {
    this._context.store.commit('modal/setShowGetPoint', false)
  }
}

export default (context, inject) => {
  const $modalGetPoint = new ModalGetPoint(context)
  inject('modalGetPoint', $modalGetPoint)
}
