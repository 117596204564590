//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasMenu from '~/mixins/hasMenu'

import IconClose from '~/assets/svg/close.svg?inline'
import IconMenu from '~/assets/svg/menu.svg?inline'
import Article from '~/models/Article'

export default {
  components: { IconClose, IconMenu },

  mixins: [hasMenu],

  data() {
    return {
      search: '',
      searchResultActive: false,
      searchResultArticles: [],
      searchLoading: false,
      searchMeta: {
        search: '',
        limit: 8,
        page: 1,
      },
    }
  },

  watch: {
    $route() {
      this.setIsMenuDrawerDisplayed(false)
    },

    isMenuDrawerDisplayed(isMenuDrawerDisplayed) {
      if (!isMenuDrawerDisplayed) {
        const modalOpen = document.querySelector(
          '.modal-above-navbar-drawer .modal.show'
        )
        modalOpen && modalOpen.ModalSurvey.hide()
      }
    },

    'searchMeta.search'(val) {
      if (val.length) {
        this.handleSearch()
      } else {
        this.searchResultArticles = []
      }
    },

    searchResultActive(val) {
      const bodyDom = document.querySelector('body')
      if (bodyDom) {
        bodyDom.style.overflowY = val ? 'hidden' : 'unset'
        bodyDom.style.height = val ? '100vh' : 'unset'
      }

      const htmlDom = document.querySelector('html')
      if (htmlDom) {
        htmlDom.style.overflowY = val ? 'hidden' : 'unset'
        htmlDom.style.height = val ? '100vh' : 'unset'
      }
    },
  },

  mounted() {
    document.body.classList.add('has-navbar')
  },

  beforeDestroy() {
    document.body.classList.remove('has-navbar')
  },
  methods: {
    handleClickViewMore() {
      this.searchResultActive = false
    },
    handleSearchFocus() {
      const self = this
      this.searchResultActive = true
      this.searchMeta.search = ''

      setTimeout(() => {
        self.$refs.searchField.focus()
      }, 100)
    },
    handleSearchBlur() {
      this.searchResultActive = false
    },
    handleClickArticle(url) {
      this.searchResultActive = false
      this.$router.push(url)
    },
    async handleSearch() {
      this.searchLoading = true

      await this.$axios
        .get('/v1/articles', {
          params: this.searchMeta,
        })
        .then((resp) => {
          this.searchResultArticles = resp.data.data.map(
            (article) => new Article(article)
          )
        })

      this.searchLoading = false
    },
  },
}
