import Model from '@fahrirusliyadi/vuejs-model'
import { generateImageURL } from '~/utils/storage'

export default class Banner extends Model {
  get $attributes() {
    return [
      'id',
      'title',
      'image_desktop',
      'image_mobile',
      'url',
      'priority',
      'is_active',
      'created_at',
      'updated_at',
    ]
  }

  get imageURL() {
    return generateImageURL(this.image)
  }

  get routePath() {
    return `/produk/${this.id}/${this.slug}`
  }
}
