class ModalSurveySuccess {
  constructor(context) {
    this._context = context
  }

  get showSurveySuccess() {
    return this?._context.store?.state?.modal?.showSurveySuccess
  }

  show() {
    this._context.store.commit('modal/setShowSurveySuccess', true)
  }

  hide() {
    this._context.store.commit('modal/setShowSurveySuccess', false)
  }
}

export default (context, inject) => {
  const $modalSurveySuccess = new ModalSurveySuccess(context)
  inject('modalSurveySuccess', $modalSurveySuccess)
}
