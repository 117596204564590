import Model from '@fahrirusliyadi/vuejs-model'
import dayjs from 'dayjs'
import padStart from 'lodash/padStart'
import Category from './Category'

/* eslint-disable accessor-pairs */

export default class Video extends Model {
  get $attributes() {
    return [
      'id',
      'category_id',
      'role_id',
      'title',
      'url',
      'description',
      'published_at',
      'created_at',
      'updated_at',
      'detail',
      'oembed',
      'user_point',
      'youtube_id',
      'category',
      'role',
    ]
  }

  set category(category) {
    this.$set('category', category && new Category(category))
  }

  get duration() {
    if (this.detail) {
      return dayjs.duration(this.detail.contentDetails.duration)
    }
  }

  get durationText() {
    const duration = this.duration

    if (duration) {
      return [
        duration.hours(),
        padStart(duration.minutes(), 2, 0),
        padStart(duration.seconds(), 2, 0),
      ]
        .filter((d) => d)
        .join(':')
    }
  }
}
