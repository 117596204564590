//
//
//
//
//

import AuthCompleteData from './AuthCompleteData.vue'
export default {
  components: { AuthCompleteData },
}
