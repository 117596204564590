import { render, staticRenderFns } from "./pretest.vue?vue&type=template&id=13cd3842&scoped=true&"
import script from "./pretest.vue?vue&type=script&lang=js&"
export * from "./pretest.vue?vue&type=script&lang=js&"
import style0 from "./pretest.vue?vue&type=style&index=0&id=13cd3842&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cd3842",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDesktop: require('/var/www/charm-app/components/NavbarDesktop.vue').default,NavbarMobile: require('/var/www/charm-app/components/NavbarMobile.vue').default,NavbarMobileDrawer: require('/var/www/charm-app/components/NavbarMobileDrawer.vue').default,AlertListModal: require('/var/www/charm-app/components/alert/AlertListModal.vue').default})
