//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Vue2Datepicker from '../vue2-datepicker/Vue2Datepicker'
import hasWindow from '~/mixins/hasWindow'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'
import AuthRegister from '~/components/auth/AuthRegister.vue'

export default {
  components: { AuthRegister },
  extends: BootstrapModal,

  mixins: [hasWindow],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'modal-above-navbar-drawer'],
    },
    refModal: {
      type: Object,
    },
    email: String,
  },
  methods: {
    handleLoginModal() {
      this.refModal.authModalLogin.modal.show()
    },
    hideModal() {
      this.modal.hide()
    },
  },
}
