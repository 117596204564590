//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import hasWindow from '~/mixins/hasWindow'

import BootstrapModal from '~/components/bootstrap/BootstrapModal'

export default {
  extends: BootstrapModal,

  mixins: [hasWindow],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'modal-above-navbar-drawer'],
    },
  },

  data() {
    return { errors: {}, input: {}, isSubmitting: false }
  },

  computed: {
    isNewPassword() {
      return Boolean(this?.$auth?.user?.is_new_password)
    },
  },

  watch: {
    '$auth.user': {
      handler(user) {
        if (user) this.input = user.toJSON()
      },
      immediate: true,
    },

    input() {
      this.$nextTick(() =>
        // Prevent modal from closing. Somehow the component updated, removing
        // the 'show' class, which is very weird because it does not happen on
        // other modals.
        this.$el.classList.toggle('show', this.$el.style.display === 'block')
      )
    },
  },

  mounted() {
    document.body.appendChild(this.$refs.modalSuccess.$el)
  },

  beforeDestroy() {
    this.$el.appendChild(this.$refs.modalSuccess.$el)
  },

  methods: {
    async handleSubmit() {
      try {
        this.isSubmitting = true

        await this.$auth.update(this.input)
        this.$refs.modalSuccess.modal.show()
      } catch (error) {
        this.errors = get(error, 'response.data.errors', {})
        this.$alert.requestError(error, {
          props: { bodyClass: ['navbar-above-modal'] },
          showPrevModal: true,
        })
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
