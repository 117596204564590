import Role from '~/models/Role'
import User from '~/models/User'

export default {
  namespaced: true,

  state: () => ({
    data: null,
    isLoading: false,
    user: null,
    // state is user has completed data
    providerId: null,
    // Default role code for guest users.
    defaultRole: Role.ROLE_GIRL,
  }),

  mutations: {
    setData: (state, data) => (state.data = data),
    setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
    setUser: (state, user) => (state.user = user),
    setDefaultRole: (state, defaultRole) => (state.defaultRole = defaultRole),
    setProviderId: (state, providerId) => (state.providerId = providerId),
  },

  actions: {
    boot({ commit, state }) {
      if (state.user && !(state.user instanceof User)) {
        commit('setUser', new User(state.user))
      }
    },
  },
}
