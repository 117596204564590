import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('menu', {
      mainMenu: 'main',
      footerMenu: 'footer',
      isMenuDrawerDisplayed: 'isDrawerDisplayed',
    }),
  },

  methods: {
    ...mapMutations('menu', {
      setIsMenuDrawerDisplayed: 'setIsDrawerDisplayed',
    }),
  },
}
