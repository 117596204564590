//
//
//
//
//
//
//
//
//
//
//
//

import AlertListModalItem from './AlertListModalItem'

export default {
  components: { AlertListModalItem },
}
