//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import BootstrapModal from '../bootstrap/BootstrapModal'
import hasWindow from '~/mixins/hasWindow'

export default {
  extends: BootstrapModal,
  mixins: [hasWindow],
  data() {
    return { errors: {}, input: {}, isSubmitting: false, provinces: [] }
  },

  mounted() {
    this.$el.addEventListener(
      'app-hidden.bs.modal',
      () => this.$modalCompleteData.hide(),
      {
        once: true,
      }
    )
    this.modal.show()
  },
  beforeMount() {
    this.loadProvince()
  },

  methods: {
    async loadProvince() {
      try {
        const response = await this.$axios.get(`v1/provinces`, {
          params: {
            limit: 34,
            orderBy: 'name',
            orderDirection: 'asc',
          },
        })
        this.provinces = response.data.data
      } catch (e) {}
    },
    async handleSubmit() {
      try {
        this.errors = {}
        this.isSubmitting = true
        await this.$auth.completeUserData(this.input)
        this.modal.hide()
      } catch (error) {
        this.errors = get(error, 'response.data.errors', {})
        this.$alert.requestError(error)
      } finally {
        this.isSubmitting = false
      }
    },
    closeModal() {
      this.modal.hide()
    },
  },
}
