import Model from '@fahrirusliyadi/vuejs-model'
import slugify from '@sindresorhus/slugify'
import dayjs from 'dayjs'
import { generateImageURL } from '../utils/storage'
import Category from './Category'

/* eslint-disable accessor-pairs */

export default class Product extends Model {
  get $attributes() {
    return [
      'id',
      'name',
      'meta_description',
      'tagline',
      'image',
      'gallery',
      'urls',
      'features',
      'description',
      'detail',
      'created_at',
      'updated_at',
      'category',
    ]
  }

  set category(category) {
    category && this.$set('category', new Category(category))
  }

  get createdAt() {
    return dayjs(this.published_at)
  }

  get galleryURLs() {
    return this.gallery && this.gallery.length
      ? this.gallery.map(generateImageURL)
      : [this.imageURL]
  }

  get imageURL() {
    return generateImageURL(this.image)
  }

  get routePath() {
    return `/produk/${this.id}/${this.slug}`
  }

  get slug() {
    return slugify(this.name)
  }
}
