//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasMenu from '~/mixins/hasMenu'
import UserFormModal from '~/components/user/UserFormModal'
import Article from '~/models/Article'

export default {
  components: { UserFormModal },

  mixins: [hasMenu],

  data() {
    return {
      search: '',
      searchResultActive: false,
      searchResultArticles: [],
      searchLoading: false,
      searchMeta: {
        search: '',
        limit: 6,
        page: 1,
      },
      dropdownSpace: '108px',
    }
  },

  computed: {
    isNewPassword() {
      return Boolean(this?.$auth?.user?.is_new_password)
    },
  },

  watch: {
    'searchMeta.search'() {
      this.handleSearch()
    },
  },

  mounted() {
    document.body.classList.add('has-navbar')
    document.body.appendChild(this.$refs.authModalLogin.$el)
    document.body.appendChild(this.$refs.authModalRegister.$el)
    document.body.appendChild(this.$refs.userFormModal.$el)
    document.body.appendChild(this.$refs.userFormPasswordModal.$el)
    if (this.$route.query.login === '1') {
      this.$refs.authModalLogin.modal.show()
    }
  },

  beforeDestroy() {
    document.body.classList.remove('has-navbar')
    this.$el.appendChild(this.$refs.authModalLogin.$el)
    this.$el.appendChild(this.$refs.authModalRegister.$el)
    this.$el.appendChild(this.$refs.userFormModal.$el)
    this.$el.appendChild(this.$refs.userFormPasswordModal.$el)
  },
  methods: {
    showDropdownNav(index) {
      const navDropdown = document.querySelectorAll(
        '.nav-dropdown-link-wrapper'
      )
      const element = document.getElementById(
        `nav-dropdown-link-wrapper-${index}`
      )
      const parentElement = document.getElementById(
        `nav-dropdown-item-${index}`
      )
      const fade = document.querySelector('.background-fade')
      fade.classList.remove('active')

      if (element.classList.contains('active')) {
        navDropdown.forEach((item) => {
          item.classList.remove('active')
          parentElement.classList.remove('active')
        })
      } else {
        element.classList.add('active')
        parentElement.classList.add('active')
      }

      navDropdown.forEach((item) => {
        if (item.classList.contains('active')) {
          fade.classList.add('active')
        }
      })

      this.dropdownSpace = this.$refs.navBox.clientHeight + 'px'
    },
    handleButtonSearch() {
      this.$refs.searchField.focus()
    },
    handleSearchFocus() {
      this.searchResultActive = true
    },
    handleSearchBlur() {
      this.searchResultActive = false
    },
    handleSearchEnter() {
      this.searchResultActive = false
      this.$refs.searchField.blur()
      if (this.$route.name === 'artikel-result') {
        this.$router
          .replace(`/artikel/result?search=${this.searchMeta.search}`)
          .catch(() => {})
      } else {
        this.$router
          .push(`/artikel/result?search=${this.searchMeta.search}`)
          .catch(() => {})
      }
      this.searchMeta.search = ''
    },
    async handleSearch() {
      this.searchLoading = true

      await this.$axios
        .get('/v1/articles', {
          params: this.searchMeta,
        })
        .then((resp) => {
          this.searchResultArticles = resp.data.data.map(
            (article) => new Article(article)
          )
        })

      this.searchLoading = false
    },
  },
}
