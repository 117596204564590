import Model from '@fahrirusliyadi/vuejs-model'
import { generateImageURL } from '../utils/storage'

export default class Gallery extends Model {
  static get TYPE_IMAGE() {
    return 'IMAGE'
  }

  static get TYPE_VIDEO() {
    return 'VIDEO'
  }

  static get types() {
    return [
      { value: this.TYPE_IMAGE, label: 'Gambar' },
      { value: this.TYPE_VIDEO, label: 'Video' },
    ]
  }

  get $attributes() {
    return [
      'id',
      'type',
      'title',
      'images',
      'url',
      'created_at',
      'updated_at',
      'oembed',
      'youtube_id',
    ]
  }

  get imageURLs() {
    return this.images && this.images.map(generateImageURL)
  }
}
