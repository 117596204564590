import slugify from '@sindresorhus/slugify'

export function generateURL(path) {
  return `${process.env.apiURL}/storage/${path}`
}

export function generateImageURL(path) {
  return `${process.env.apiURL}/v1/images/${path}`
}

export function generateUrlPath(id, title) {
  return `/artikel/${id}/${slugGenerate(title)}`
}

export function slugGenerate(title) {
  return `${slugify(title.substring(0, 75))}`
}
