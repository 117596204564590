/**
 * Stores temporary data.
 */

import Vue from 'vue'

export default {
  state: () => ({}),

  getters: { get: (state) => (key) => state[key] },

  mutations: { set: (state, [key, value]) => Vue.set(state, key, value) },
}
