//
//
//
//
//

import SwapGoldPointFailed from '~/components/modal/SwapGoldPointFailed'
export default {
  components: { SwapGoldPointFailed },
}
