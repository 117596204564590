
let routerBeforeEachListened = false

export default {
  props: { bodyClass: { type: Array, default: () => [] } },

  beforeMount() {
    this.listenRouterBeforeEach()
  },

  mounted() {
    const ModalSurvey = require('bootstrap.native/dist/components/modal-native')
    this.modal = new ModalSurvey(this.$el)

    this.$el.addEventListener('show.bs.modal', this.addBodyClass)
    this.$el.addEventListener('hidden.bs.modal', this.dispatchHiddenEvent)
    this.$el.addEventListener('app-hidden.bs.modal', this.removeBodyClass)
  },

  beforeDestroy() {
    this.modal.dispose()
    this.$el.removeEventListener('show.bs.modal', this.addBodyClass)
    this.$el.removeEventListener('hidden.bs.modal', this.dispatchHiddenEvent)
    this.$el.removeEventListener('app-hidden.bs.modal', this.removeBodyClass)
    this.$el.classList.contains('show') && this.removeBodyClass()
  },

  methods: {
    listenRouterBeforeEach() {
      if (routerBeforeEachListened) return

      // Close open modal before navigate.
      this.$router.beforeEach((to, from, next) => {
        const emulateTransitionEnd = require('shorter-js/src/misc/emulateTransitionEnd')
          .default
        const modalOpen = document.querySelector('.modal.show')
        const backdropOpen = document.querySelector('.modal-backdrop.show')

        // Close open modal.
        if (modalOpen) {
          modalOpen.ModalSurvey.hide()
          modalOpen.addEventListener('app-hidden.bs.modal', () => next(), {
            once: true,
          })
        }
        // Close leftover backdrop.
        else if (backdropOpen) {
          emulateTransitionEnd(backdropOpen, () =>
            backdropOpen.parentElement.removeChild(backdropOpen)
          )
          backdropOpen.classList.remove('show')
          next()
        } else {
          next()
        }
      })
      routerBeforeEachListened = true
    },

    /**
     * Dispatch custom hidden event when modal including backdrop is hidden
     * completely.
     */
    dispatchHiddenEvent() {
      const emulateTransitionEnd = require('shorter-js/src/misc/emulateTransitionEnd')
        .default
      const backdrop = document.querySelector('.modal-backdrop')
      const dispatchHiddenEvent = () =>
        this.$el.dispatchEvent(new CustomEvent('app-hidden.bs.modal'))

      if (backdrop && !backdrop.classList.contains('show')) {
        emulateTransitionEnd(backdrop, dispatchHiddenEvent)
      } else {
        dispatchHiddenEvent()
      }
    },

    addBodyClass() {
      const prevModal = document.querySelector('.modal.show')
      const addClass = () => document.body.classList.add(...this.bodyClass)

      if (prevModal) {
        prevModal.addEventListener('app-hidden.bs.modal', addClass, {
          once: true,
        })
      } else {
        addClass()
      }
    },

    removeBodyClass() {
      document.body.classList.remove(...this.bodyClass)
    },
  },

  render() {
    return this.$slots.default
  },
}
