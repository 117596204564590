export default function ({ store }) {
  const styleInnerWidth = document.createElement('style')
  const styleInnerHeight = document.createElement('style')
  document.head.appendChild(styleInnerWidth)
  document.head.appendChild(styleInnerHeight)

  store.watch(
    (state) => state.window.innerWidth,
    (innerWidth) => {
      // Manually add style.
      //
      // Cannot use head as function on nuxt.config.js, error:
      // "Cannot merge meta. Avoid using head as a function!"
      styleInnerWidth.innerHTML = innerWidth
        ? `:root { --inner-width: ${innerWidth}px }`
        : ''
    }
  )

  store.watch(
    (state) => state.window.innerHeight,
    (innerHeight) => {
      styleInnerHeight.innerHTML = innerHeight
        ? `:root { --inner-height: ${innerHeight}px }`
        : ''
    }
  )
}
