import debounce from 'lodash/debounce'

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export default {
  state: () => ({
    booted: false,
    innerWidth: breakpoints.sm,
    innerHeight: null,
  }),

  getters: {
    /**
     * Check whether the current screen size is >= than the given breakpoint.
     *
     * @param {String} breakpoint Breakpoint name.
     */
    breakpointUp: (state) => (breakpoint) =>
      state.innerWidth >= breakpoints[breakpoint],
  },

  mutations: {
    setInnerWidth: (state, innerWidth) => (state.innerWidth = innerWidth),
    setInnerHeight: (state, innerHeight) => (state.innerHeight = innerHeight),
  },

  actions: {
    nuxtServerInit({ commit }) {
      let width

      if (this.$device.isMobile) {
        width = breakpoints.sm
      } else if (this.$device.isTablet) {
        width = breakpoints.md
      } else {
        width = breakpoints.lg
      }

      commit('setInnerWidth', width)
    },

    /**
     * Initialize store.
     */
    boot({ commit, state }) {
      if (state.booted) {
        return
      }

      const setInnerWidthDebounced = debounce(() => {
        // Use document.documentElement.offsetWidth instead of window.innerWidth
        // because on some browsers it can be very inconsistent.
        //
        // https://stackoverflow.com/a/54812656
        if (state.innerWidth !== document.documentElement.offsetWidth) {
          commit('setInnerWidth', document.documentElement.offsetWidth)
        }
      })
      const setInnerHeightDebounced = debounce(() => {
        if (state.innerHeight !== window.innerHeight) {
          commit('setInnerHeight', window.innerHeight)
        }
      })

      addEventListener('resize', setInnerWidthDebounced)
      addEventListener('resize', setInnerHeightDebounced)
      // Also listen to orientationchange because Safari on iOS doesn't trigger
      // resize event going from portrait to landscape when added to home screen.
      addEventListener('orientationchange', setInnerWidthDebounced)
      setInnerWidthDebounced()
      setInnerHeightDebounced()
    },
  },
}
