export function generateAbbr(text) {
  if (text === null) return text
  if (text.includes(' ')) {
    return text.match(/\b\w/g).join('').substr(0, 2)
  } else {
    return text.substr(0, 2)
  }
}

/**
 * Escape quote.
 *
 * @see https://stackoverflow.com/a/9756789
 * @param {String} s
 * @param {Boolean} preserveCR
 */
export function escQuote(s, preserveCR) {
  preserveCR = preserveCR ? '&#13;' : '\n'
  return (
    ('' + s) /* Forces the conversion to string. */
      .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
      .replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
      .replace(/"/g, '&quot;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      /*
      You may add other replacements here for HTML only
      (but it's not necessary).
      Or for XML, only if the named entities are defined in its DTD.
      */
      .replace(/\r\n/g, preserveCR) /* Must be before the next replacement. */
      .replace(/[\r\n]/g, preserveCR)
  )
}

/**
 * Escape text for HTML attribute.
 *
 * @param {String} text
 * @returns {String}
 */
export function escAttr(text) {
  return escQuote(text)
}

/**
 * @link https://locutus.io/php/strings/nl2br/
 * @param {String} str
 * @param {Boolean} isXhtml
 * @returns
 */
export function nl2br(str, isXhtml) {
  //  discuss at: https://locutus.io/php/nl2br/
  // original by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Philip Peterson
  // improved by: Onno Marsman (https://twitter.com/onnomarsman)
  // improved by: Atli Þór
  // improved by: Brett Zamir (https://brett-zamir.me)
  // improved by: Maximusya
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Reynier de la Rosa (https://scriptinside.blogspot.com.es/)
  //    input by: Brett Zamir (https://brett-zamir.me)
  //   example 1: nl2br('Kevin\nvan\nZonneveld')
  //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
  //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false)
  //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
  //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true)
  //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
  //   example 4: nl2br(null)
  //   returns 4: ''
  // Some latest browsers when str is null return and unexpected null value
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  // Adjust comment to avoid issue on locutus.io display
  const breakTag =
    isXhtml || typeof isXhtml === 'undefined' ? '<br ' + '/>' : '<br>'
  return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}
