//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BootstrapModal from '../bootstrap/BootstrapModal'
import hasMenu from '~/mixins/hasMenu'

export default {
  extends: BootstrapModal,

  mixins: [hasMenu],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'modal-above-navbar-drawer'],
    },
  },

  methods: {
    handleDismissClick() {
      this.modal.hide()

      const getBackdrop = document.getElementsByClassName(
        'modal-backdrop fade show'
      )
      for (let i = 0; i < getBackdrop.length; i++) {
        getBackdrop[i].classList.remove('show')
      }

      if (this.$route.path !== '/') {
        this.$router.push('/')
      }

      if (this.isMenuDrawerDisplayed) {
        this.setIsMenuDrawerDisplayed(false)
      }
    },
  },
}
