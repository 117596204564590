//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '~/mixins/hasWindow'
import IconClose from '~/assets/svg/close.svg?inline'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'

export default {
  components: { IconClose },
  extends: BootstrapModal,
  mixins: [hasWindow],

  props: {
    bodyClass: {
      type: Array,
      default: () => ['navbar-above-modal', 'modal-above-navbar-drawer'],
    },
  },

  data() {
    return {
      input: {},
      isSubmitting: false,
      isUploading: false,
      replace: false,
      provinces: [],
      formMenstruationID: {
        no: (Math.random() + 1).toString(36).substring(7),
        yes: (Math.random() + 1).toString(36).substring(7),
      },
    }
  },

  watch: {
    '$auth.user': {
      handler(user) {
        if (user) this.input = user.toJSON()
      },
      deep: true,
    },
  },

  beforeMount() {
    this.loadProvince()
  },

  methods: {
    async loadProvince() {
      try {
        const response = await this.$axios.get(`v1/provinces`, {
          params: {
            limit: 34,
            orderBy: 'name',
            orderDirection: 'asc',
          },
        })
        this.provinces = response.data.data
      } catch (e) {}
    },

    async handleAvatarChange(event) {
      try {
        this.isUploading = true
        this.replace = true
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        const response = await this.$axios.post('v1/storage', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        this.$set(this.input, 'avatar', response.data.data.path)
      } catch (error) {
        this.$alert.requestError(error)
        this.replace = false
      } finally {
        this.isUploading = false
        this.replace = true
        event.target.value = ''
      }
    },

    async handleSubmit() {
      try {
        this.isSubmitting = true

        await this.$auth.update(this.input)
        this.$alert.alert('success', 'Profil berhasil diubah.')
      } catch (error) {
        this.$alert.requestError(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
