var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column min-vh-100 overflow-hidden"},[(_vm.breakpointUp('lg'))?_c('NavbarDesktop'):[_c('NavbarMobile'),_vm._v(" "),_c('NavbarMobileDrawer')],_vm._v(" "),_c('Nuxt',{staticClass:"flex-grow-1"}),_vm._v(" "),(
      _vm.breakpointUp('lg') &&
      _vm.showFooter &&
      _vm.$route.fullPath !== '/gold-points/gacha' &&
      !_vm.$route.fullPath.includes('/gold-points/trivia') &&
      _vm.$route.fullPath !== '/maximix'
    )?_c('Footer'):(
      !_vm.breakpointUp('lg') &&
      _vm.showFooter &&
      _vm.$route.fullPath !== '/gold-points/gacha' &&
      !_vm.$route.fullPath.includes('/gold-points/trivia') &&
      _vm.$route.fullPath !== '/maximix'
    )?_c('FooterMobile'):_vm._e(),_vm._v(" "),_c('AlertListModal'),_vm._v(" "),_c('AuthModalCompleteData'),_vm._v(" "),_c('RegistBeforeExit',{ref:"registBeforeExit",attrs:{"ref-modal":_vm.$refs,"email":_vm.email,"modal-data":_vm.modalData},on:{"update":function($event){_vm.email = $event}}}),_vm._v(" "),_c('AuthModalLogin',{ref:"authModalLogin",attrs:{"ref-modal":_vm.$refs}}),_vm._v(" "),_c('AuthModalRegister',{ref:"authModalRegister",attrs:{"ref-modal":_vm.$refs,"email":_vm.email},on:{"update":function($event){_vm.email = $event}}}),_vm._v(" "),_c('ModalGetPoint'),_vm._v(" "),_c('ModalSwapGoldFailed'),_vm._v(" "),_c('ModalSwapGoldSuccess'),_vm._v(" "),(
      _vm.showFooter &&
      _vm.$route.fullPath !== '/gold-points/gacha' &&
      _vm.$route.fullPath !== '/maximix'
    )?_c('floating-gacha-icon'):_vm._e(),_vm._v(" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"bottom-right",attrs:{"id":"button-to-top"},on:{"click":_vm.scrollTop}},[_c('span',{staticClass:"scroll-top"},[_c('fa',{attrs:{"icon":"chevron-up"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }