import { render, staticRenderFns } from "./ModalSwapGoldFailed.vue?vue&type=template&id=74fb819c&"
import script from "./ModalSwapGoldFailed.vue?vue&type=script&lang=js&"
export * from "./ModalSwapGoldFailed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwapGoldPointFailed: require('/var/www/charm-app/components/modal/SwapGoldPointFailed.vue').default})
