import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('window', {
      windowInnerWidth: 'innerWidth',
    }),
    ...mapGetters('window', ['breakpointUp']),
  },
}
