//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconClose from '../../assets/svg/close.svg?inline'
import hasWindow from '~/mixins/hasWindow'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'

export default {
  components: { IconClose },
  extends: BootstrapModal,
  mixins: [hasWindow],

  data() {
    return {
      imageUrl: '/images/get-point-image.png',
    }
  },

  mounted() {
    this.$el.addEventListener(
      'app-hidden.bs.modal',
      () => this.$modalGetPoint.hide(),
      {
        once: true,
      }
    )
    this.modal.show()
  },

  methods: {
    handleClose() {
      this.modal.hide()
    },
    handleGo() {
      this.modal.hide()
      this.$router.replace('/profile')
    },
  },
}
