class ModalSwapGoldSuccess {
  constructor(context) {
    this._context = context
  }

  get showSwapGoldSuccess() {
    return this?._context.store?.state?.modal?.showSwapGoldSuccess
  }

  show() {
    this._context.store.commit('modal/setShowSwapGoldSuccess', true)
  }

  hide() {
    this._context.store.commit('modal/setShowSwapGoldSuccess', false)
  }
}

export default (context, inject) => {
  const $modalSwapGoldSuccess = new ModalSwapGoldSuccess(context)
  inject('modalSwapGoldSuccess', $modalSwapGoldSuccess)
}
