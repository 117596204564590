class ModalSwapGoldFailed {
  constructor(context) {
    this._context = context
  }

  get showSwapGoldFailed() {
    return this?._context.store?.state?.modal?.showSwapGoldFailed
  }

  show() {
    this._context.store.commit('modal/setShowSwapGoldFailed', true)
  }

  hide() {
    this._context.store.commit('modal/setShowSwapGoldFailed', false)
  }
}

export default (context, inject) => {
  const $modalSwapGoldFailed = new ModalSwapGoldFailed(context)
  inject('modalSwapGoldFailed', $modalSwapGoldFailed)
}
