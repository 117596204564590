import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import camelCase from 'lodash/camelCase'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import omit from 'lodash/omit'
import unionBy from 'lodash/unionBy'

export const createResourceModule = (moduleName, Model) => {
  const getInitialState = () => ({
    isBooted: false,
    all: [],
    isFetching: {},
    didFetch: {},
    result: {},
    isFetchingById: {},
    didFetchById: {},
  })

  return {
    namespaced: true,

    state: () => getInitialState(),

    getters: {
      isFetching: (state) => (payload = {}) => {
        const key = JSON.stringify(payload)
        return !!state.isFetching[key]
      },
      didFetch: (state) => (payload = {}) => {
        const key = JSON.stringify(payload)
        return !!state.didFetch[key]
      },
      find: (state) => (predicate) =>
        find(
          state.all,
          typeof predicate === 'number' ? ['id', predicate] : predicate
        ),
      get: (state, getters) => (payload = {}) => {
        const result = getters.getResult(payload)
        const items = result ? result.items.map(getters.find) : []

        return items.filter((item) => item)
      },
      getResult: (state) => (payload = {}) => {
        const key = JSON.stringify(payload)
        return state.result[key]
      },
    },

    mutations: {
      setIsBooted: (state, isBooted) => (state.isBooted = isBooted),
      setAll: (state, all) => (state.all = all),
      add: (state, items) =>
        (state.all = unionBy(items, state.all, ({ id }) => id)),
      update: (state, data) => {
        const record = find(state.all, ['id', data.id])
        record ? Object.assign(record, data) : state.all.push(data)
      },
      remove: (state, id) => {
        const index = findIndex(state.all, ['id', id])

        if (index !== -1) {
          state.all = [
            ...state.all.slice(0, index),
            ...state.all.slice(index + 1),
          ]
        }
      },
      putIsFetching: (state, [key, isFetching]) =>
        Vue.set(state.isFetching, key, isFetching),
      putDidFetch: (state, [key, didFetch]) =>
        Vue.set(state.didFetch, key, didFetch),
      putResult: (state, [key, result]) => Vue.set(state.result, key, result),
      putIsFetchingById: (state, [ID, isFetching]) =>
        Vue.set(state.isFetchingById, ID, isFetching),
      putDidFetchById: (state, [ID, didFetch]) =>
        Vue.set(state.didFetchById, ID, didFetch),
      invalidate: (state) => Object.assign(state, getInitialState()),
    },

    actions: {
      /**
       * Initialize store.
       */
      boot({ commit, state }) {
        if (state.isBooted) {
          return
        }

        const all = state.all.map((item) =>
          item instanceof Model ? Model : new Model(item)
        )
        commit('setAll', all)
        commit('setIsBooted', true)
      },

      async fetch({ commit }, payload = {}) {
        const key = JSON.stringify(payload)

        commit('putIsFetching', [key, true])

        try {
          const response = await this.$axios.get(`v1/${moduleName}`, {
            params: payload,
          })
          const items = response.data.data.map((attr) => new Model(attr))
          const IDs = items.map(({ id }) => id)
          const meta = omit(response.data, 'data')

          commit('add', items)
          commit('putResult', [key, { items: IDs, meta }])
        } finally {
          commit('putDidFetch', [key, true])
          commit('putIsFetching', [key, false])
        }
      },

      async fetchById({ commit }, ID) {
        commit('putIsFetchingById', [ID, true])

        try {
          const response = await this.$axios.get(`v1/${moduleName}/${ID}`)
          const item = new Model(response.data.data)

          commit('add', [item])
        } finally {
          commit('putDidFetchById', [ID, true])
          commit('putIsFetchingById', [ID, false])
        }
      },

      maybeFetch: async ({ dispatch, getters }, payload = {}) => {
        if (!getters.didFetch(payload) && !getters.isFetching(payload)) {
          await dispatch('fetch', payload)
        }
      },

      maybeFetchById: async ({ dispatch, state }, ID = {}) => {
        if (!state.didFetchById[ID] && !state.isFetchingById[ID]) {
          await dispatch('fetchById', ID)
        }
      },

      invalidateAndFetch: async (
        { commit, dispatch, getters },
        payload = {}
      ) => {
        await dispatch('fetch', payload)

        const items = getters.get(payload)
        const result = getters.getResult(payload)
        const key = JSON.stringify(payload)

        commit('invalidate')
        commit('add', items)
        commit('putResult', [key, result])
        commit('putDidFetch', [key, true])
        commit('putIsFetching', [key, false])
      },
    },
  }
}

export const createResourceMixin = (moduleName, singularName) => {
  const isFetching = camelCase(`isFetching ${moduleName}`)
  const didFetch = camelCase(`didFetch ${moduleName}`)
  const find = camelCase(`find ${singularName}`)
  const get = camelCase(`get ${moduleName}`)
  const getResult = camelCase(`get ${moduleName} Result`)

  const invalidate = camelCase(`invalidate ${moduleName}`)
  const add = camelCase(`add ${moduleName}`)
  const update = camelCase(`update ${singularName}`)
  const remove = camelCase(`remove ${singularName}`)
  const fetch = camelCase(`fetch ${moduleName}`)
  const maybeFetch = camelCase(`maybeFetch ${moduleName}`)
  const fetchById = camelCase(`fetch ${singularName} ById`)
  const maybeFetchById = camelCase(`maybeFetch ${singularName} ById`)
  const invalidateAndFetch = camelCase(`invalidateAndFetch ${moduleName}`)

  const unsubscribeUpdate = camelCase(`unsubscribeUpdate ${moduleName}`)
  const unsubscribeRemove = camelCase(`unsubscribeRemove ${moduleName}`)
  const unsubscribeInvalidate = camelCase(`unsubscribeInvalidate ${moduleName}`)
  const onUpdate = camelCase(`onUpdate ${singularName}`)
  const onRemove = camelCase(`onRemove ${singularName}`)
  const onInvalidate = camelCase(`onInvalidate ${moduleName}`)

  return {
    computed: {
      ...mapGetters(moduleName, {
        [isFetching]: 'isFetching',
        [didFetch]: 'didFetch',
        [find]: 'find',
        [get]: 'get',
        [getResult]: 'getResult',
      }),
    },

    methods: {
      ...mapMutations(moduleName, {
        [invalidate]: 'invalidate',
        [add]: 'add',
        [update]: 'update',
        [remove]: 'remove',
      }),
      ...mapActions(moduleName, {
        [fetch]: 'fetch',
        [maybeFetch]: 'maybeFetch',
        [fetchById]: 'fetchById',
        [maybeFetchById]: 'maybeFetchById',
        [invalidateAndFetch]: 'invalidateAndFetch',
      }),
    },

    beforeMount() {
      this[unsubscribeUpdate] = this.$store.subscribe(
        (mutation) =>
          mutation.type === `${moduleName}/update` &&
          this[onUpdate] &&
          this[onUpdate]()
      )
      this[unsubscribeRemove] = this.$store.subscribe(
        (mutation) =>
          mutation.type === `${moduleName}/remove` &&
          this[onRemove] &&
          this[onRemove]()
      )
      this[unsubscribeInvalidate] = this.$store.subscribe(
        (mutation) =>
          mutation.type === `${moduleName}/invalidate` &&
          this[onInvalidate] &&
          this[onInvalidate]()
      )
    },

    beforeDestroy() {
      this[unsubscribeUpdate]()
      this[unsubscribeRemove]()
      this[unsubscribeInvalidate]()
    },
  }
}
