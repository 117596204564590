import { parse } from 'cookie'
import get from 'lodash/get'
import mitt from 'mitt'
import User from '../models/User'
import Role from '~/models/Role'

class Auth {
  constructor(context) {
    this._context = context
    this._emitter = mitt()

    this._initAPI()
    this._load()
  }

  get data() {
    return this._context.store.state.auth.data
  }

  get user() {
    return this._context.store.state.auth.user
  }

  get providerId() {
    return this._context.store.state.auth.providerId
  }

  get isLoggedIn() {
    return Boolean(this.user)
  }

  get hasProviderId() {
    return Boolean(this.providerId)
  }

  get isLoading() {
    return this._context.store.state.auth.isLoading
  }

  get defaultRole() {
    return this._context.store.state.auth.defaultRole
  }

  set defaultRole(defaultRole) {
    this._context.store.commit('auth/setDefaultRole', defaultRole)

    if (process.client) {
      document.cookie = defaultRole
        ? `auth/defaultRole=${defaultRole}; path=/;`
        : `auth/defaultRole=${defaultRole}; path=/; expires=${new Date().toUTCString()}`
    }
  }

  get isGirl() {
    if (this.user) return this.user.isGirl
    return !this.defaultRole || this.defaultRole === Role.ROLE_GIRL
  }

  /**
   * Role code for filtering contents.
   */
  get contentRole() {
    return this.isGirl ? Role.ROLE_GIRL : Role.ROLE_PARENT
  }

  /**
   * Log in.
   *
   * @param {Object} credentials
   */

  async logIn(credentials, authData) {
    let response
    let data
    let redirectTo = this._context.route.query.redirectTo || '/'

    if (credentials) {
      response = await this._context.$axios.post('v1/auth/log-in', credentials)
    }

    if (authData) {
      data = authData

      redirectTo =
        this._context.route.query.redirectTo ||
        localStorage.getItem('redirectTo') ||
        '/'
    } else {
      data = get(response, 'data.data')
    }

    if (data) {
      if (process.client) {
        const auth = JSON.stringify(data)
        const expires = new Date(data.expires).toUTCString()
        document.cookie = `auth=${auth}; path=/; expires=${expires}`
        if (data.provider_id) {
          this._context.store.commit('auth/setProviderId', data.provider_id)
          document.cookie = `provider_id=${data.provider_id}; path=/;`
          document.cookie = `show_complete_data=1; path=/;`

          delete data.provider_id
        }
      }

      await this._logInWithData(data)

      if (redirectTo?.startsWith('http')) {
        location.href =
          redirectTo +
          `${redirectTo && redirectTo.split('?').length > 1 ? '&' : '?'}` +
          `registration-success=1${
            !credentials && authData.is_register === '1' ? '&is-register=1' : ''
          }`
      } else {
        this._context.redirect(
          redirectTo +
            `${redirectTo && redirectTo.split('?').length > 1 ? '&' : '?'}` +
            `registration-success=1${
              !credentials && authData.is_register === '1'
                ? '&is-register=1'
                : ''
            }`
        )
      }
    }
  }

  /**
   * Log in with social.
   *
   * @param {String} socialType
   */

  loginWithSocial(socialType) {
    window.location.href = `${this._context.env.apiURL}/${socialType}`
  }
  /**
   * Register.
   *
   * @param {Object} user
   */

  async register(user) {
    const response = await this._context.$axios.post('v1/users', user)
    const authData = get(response, 'data.data')
    await this.logIn(null, authData)
  }

  /**
   * Completed user data.
   *
   * @param {Object} userData
   */

  async completeUserData(userData) {
    const redirectTo = localStorage.getItem('redirectTo') || '/'

    const bodyParams = {
      provider_id: this.providerId,
      ...userData,
    }
    await this._context.$axios.post('v1/auth/update-phone', bodyParams)

    this._context.store.commit('auth/setProviderId', null)
    document.cookie = 'provider_id=; path=/; max-age=0'
    document.cookie = 'show_complete_data=; path=/; max-age=0'

    await this._fetchUser()

    if (redirectTo?.startsWith('http')) {
      location.href = redirectTo
    } else {
      this._context.redirect(redirectTo)
    }
  }

  /**
   * authCheck
   */
  authCheck(next) {
    if (!this.isLoggedIn) {
      next(false)
      setTimeout(() => {
        this._context.redirect(
          `/auth?redirectTo=${this._context.route.fullPath}`
        )
      }, 300)
    } else if (this.hasProviderId) {
      localStorage.setItem('redirectTo', this._context.route.fullPath)
      this._context.store.commit('modal/setShowCompleteData', true)
    } else {
      next(true)
    }
  }

  /**
   * Log out.
   */
  logOut() {
    const fromModalLogin = document
      .getElementById('modal-auth-login')
      .classList.contains('show')

    let fullPath = this._context.route.fullPath
    if (fullPath.includes('&is-register=1')) {
      fullPath = fullPath.replace('&is-register=1', '')
    } else if (fullPath.includes('is-register=1')) {
      fullPath = fullPath.replace('is-register=1', '')
    }

    let loginPath = `/?redirectTo=${fullPath}`

    if (this._context.route.name === 'auth-social-callback') {
      loginPath = '/auth'
    }

    this._context.store.commit('auth/setData', null)
    this._context.store.commit('auth/setUser', null)
    this._context.store.commit('auth/setProviderId', null)
    this._context.$axios.setToken(false)

    if (this._context.route.path !== '/auth' && !fromModalLogin) {
      if (process.client) {
        document.cookie = 'auth=; path=/; max-age=0'
        document.cookie = 'provider_id=; path=/; max-age=0'
        document.cookie = 'show_complete_data=; path=/; max-age=0'
        location.href = loginPath
      } else {
        this._context.redirect(loginPath)
      }
    }

    localStorage.removeItem('isRegister')
  }

  /**
   * Update user profile.
   *
   * @param {Object} data
   */
  async update(data) {
    const response = await this._context.$axios.patch(
      `v1/users/${this.user.id}`,
      data
    )
    const user = new User(response.data.data)

    this._context.store.commit('auth/setUser', user)
  }

  on(event, callback) {
    this._emitter.on(event, callback)
  }

  off(event, callback) {
    this._emitter.off(event, callback)
  }

  once(event, callback) {
    const cb = () => {
      callback()
      this.off(event, cb)
    }

    this.on(event, cb)
  }

  /**
   * Log in using auth data.
   *
   * @param {Object} data
   */
  async _logInWithData(data) {
    this._context.store.commit('auth/setData', data)
    this._context.$axios.setToken(data.access_token, 'Bearer')
    this.user || (await this._fetchUser())
  }

  /**
   * Load using saved data.
   */
  async _load() {
    this._context.store.commit('auth/setIsLoading', true)

    try {
      const cookieStr = process.client
        ? document.cookie
        : this._context.req.headers.cookie
      const cookies = parse(cookieStr || '')
      this.defaultRole = cookies['auth/defaultRole']

      if (cookies.auth) {
        const data = JSON.parse(cookies.auth)
        await this._logInWithData(data)
      }
      if (cookies.provider_id) {
        this._context.store.commit('auth/setProviderId', cookies.provider_id)
      }
    } finally {
      this._context.store.commit('auth/setIsLoading', false)
      this._emitter.emit('loaded')
    }
  }

  /**
   * Initialize Axios API integration.
   */
  _initAPI() {
    this._context.$axios.onError((error) => {
      const status = get(error, 'response.status')
      // Log out if token expired.
      status === 401 && this.logOut()
    })
  }

  /**
   * Fethc user data.
   */
  async _fetchUser() {
    const response = await this._context.$axios.get(`v1/auth/me`)
    const user = new User(response.data.data)

    this._context.store.commit('auth/setUser', user)
  }
}

export default (context, inject) => {
  const $auth = new Auth(context)
  inject('auth', $auth)
}
