import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1702579f = () => interopDefault(import('../pages/artikel/index.vue' /* webpackChunkName: "pages/artikel/index" */))
const _2718446b = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _31ba5be9 = () => interopDefault(import('../pages/beranda.vue' /* webpackChunkName: "pages/beranda" */))
const _2cb207c4 = () => interopDefault(import('../pages/data-deletion/index.vue' /* webpackChunkName: "pages/data-deletion/index" */))
const _c18596ec = () => interopDefault(import('../pages/galeri.vue' /* webpackChunkName: "pages/galeri" */))
const _6c76113a = () => interopDefault(import('../pages/gold-points/index.vue' /* webpackChunkName: "pages/gold-points/index" */))
const _cb0a4cc8 = () => interopDefault(import('../pages/inspirasi.vue' /* webpackChunkName: "pages/inspirasi" */))
const _76eb6802 = () => interopDefault(import('../pages/kelas-online/index.vue' /* webpackChunkName: "pages/kelas-online/index" */))
const _04a5db33 = () => interopDefault(import('../pages/kuis-mitos-fakta.vue' /* webpackChunkName: "pages/kuis-mitos-fakta" */))
const _423bb06a = () => interopDefault(import('../pages/maximix/index.vue' /* webpackChunkName: "pages/maximix/index" */))
const _01ea5404 = () => interopDefault(import('../pages/pre-survey.vue' /* webpackChunkName: "pages/pre-survey" */))
const _5bf5191a = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _91d39aa0 = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _9eaf0248 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _2a22f796 = () => interopDefault(import('../pages/tanya-kami.vue' /* webpackChunkName: "pages/tanya-kami" */))
const _702786f3 = () => interopDefault(import('../pages/tentang.vue' /* webpackChunkName: "pages/tentang" */))
const _3e48d041 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _9275855e = () => interopDefault(import('../pages/artikel/indeks.vue' /* webpackChunkName: "pages/artikel/indeks" */))
const _7d4a95a0 = () => interopDefault(import('../pages/artikel/result.vue' /* webpackChunkName: "pages/artikel/result" */))
const _87b7d3f6 = () => interopDefault(import('../pages/auth/log-in.vue' /* webpackChunkName: "pages/auth/log-in" */))
const _cb256332 = () => interopDefault(import('../pages/auth/lupa-password.vue' /* webpackChunkName: "pages/auth/lupa-password" */))
const _0a6eea1a = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _248ad2e0 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _00c8c37e = () => interopDefault(import('../pages/auth/social-callback.vue' /* webpackChunkName: "pages/auth/social-callback" */))
const _a25e1f54 = () => interopDefault(import('../pages/gold-points/gacha/index.vue' /* webpackChunkName: "pages/gold-points/gacha/index" */))
const _d931f6a4 = () => interopDefault(import('../pages/gold-points/swap-gold/index.vue' /* webpackChunkName: "pages/gold-points/swap-gold/index" */))
const _9a4eb47c = () => interopDefault(import('../pages/gold-points/trivia.vue' /* webpackChunkName: "pages/gold-points/trivia" */))
const _1d804915 = () => interopDefault(import('../pages/kelas-online/posttest.vue' /* webpackChunkName: "pages/kelas-online/posttest" */))
const _3d2053bc = () => interopDefault(import('../pages/kelas-online/pretest.vue' /* webpackChunkName: "pages/kelas-online/pretest" */))
const _0166c61e = () => interopDefault(import('../pages/produk/kategori/_id/_slug.vue' /* webpackChunkName: "pages/produk/kategori/_id/_slug" */))
const _0de1e9c2 = () => interopDefault(import('../pages/artikel/_id/_slug.vue' /* webpackChunkName: "pages/artikel/_id/_slug" */))
const _7b7fd453 = () => interopDefault(import('../pages/produk/_id/_slug.vue' /* webpackChunkName: "pages/produk/_id/_slug" */))
const _342b707c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/artikel",
    component: _1702579f,
    name: "artikel"
  }, {
    path: "/auth",
    component: _2718446b,
    name: "auth"
  }, {
    path: "/beranda",
    component: _31ba5be9,
    name: "beranda"
  }, {
    path: "/data-deletion",
    component: _2cb207c4,
    name: "data-deletion"
  }, {
    path: "/galeri",
    component: _c18596ec,
    name: "galeri"
  }, {
    path: "/gold-points",
    component: _6c76113a,
    name: "gold-points"
  }, {
    path: "/inspirasi",
    component: _cb0a4cc8,
    name: "inspirasi"
  }, {
    path: "/kelas-online",
    component: _76eb6802,
    name: "kelas-online"
  }, {
    path: "/kuis-mitos-fakta",
    component: _04a5db33,
    name: "kuis-mitos-fakta"
  }, {
    path: "/maximix",
    component: _423bb06a,
    name: "maximix"
  }, {
    path: "/pre-survey",
    component: _01ea5404,
    name: "pre-survey"
  }, {
    path: "/privacy-policy",
    component: _5bf5191a,
    name: "privacy-policy"
  }, {
    path: "/produk",
    component: _91d39aa0,
    name: "produk"
  }, {
    path: "/profile",
    component: _9eaf0248,
    name: "profile"
  }, {
    path: "/tanya-kami",
    component: _2a22f796,
    name: "tanya-kami"
  }, {
    path: "/tentang",
    component: _702786f3,
    name: "tentang"
  }, {
    path: "/unsubscribe",
    component: _3e48d041,
    name: "unsubscribe"
  }, {
    path: "/artikel/indeks",
    component: _9275855e,
    name: "artikel-indeks"
  }, {
    path: "/artikel/result",
    component: _7d4a95a0,
    name: "artikel-result"
  }, {
    path: "/auth/log-in",
    component: _87b7d3f6,
    name: "auth-log-in"
  }, {
    path: "/auth/lupa-password",
    component: _cb256332,
    name: "auth-lupa-password"
  }, {
    path: "/auth/register",
    component: _0a6eea1a,
    name: "auth-register"
  }, {
    path: "/auth/reset-password",
    component: _248ad2e0,
    name: "auth-reset-password"
  }, {
    path: "/auth/social-callback",
    component: _00c8c37e,
    name: "auth-social-callback"
  }, {
    path: "/gold-points/gacha",
    component: _a25e1f54,
    name: "gold-points-gacha"
  }, {
    path: "/gold-points/swap-gold",
    component: _d931f6a4,
    name: "gold-points-swap-gold"
  }, {
    path: "/gold-points/trivia",
    component: _9a4eb47c,
    name: "gold-points-trivia"
  }, {
    path: "/kelas-online/posttest",
    component: _1d804915,
    name: "kelas-online-posttest"
  }, {
    path: "/kelas-online/pretest",
    component: _3d2053bc,
    name: "kelas-online-pretest"
  }, {
    path: "/produk/kategori/:id?/:slug?",
    component: _0166c61e,
    name: "produk-kategori-id-slug"
  }, {
    path: "/artikel/:id/:slug?",
    component: _0de1e9c2,
    name: "artikel-id-slug"
  }, {
    path: "/produk/:id/:slug?",
    component: _7b7fd453,
    name: "produk-id-slug"
  }, {
    path: "/",
    component: _342b707c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
