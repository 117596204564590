//
//
//
//
//

import GetPoint from '~/components/modal/GetPoint'
export default {
  components: { GetPoint },
}
