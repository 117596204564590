import Model from '@fahrirusliyadi/vuejs-model'
import { generateURL } from '~/utils/storage'

export default class Booklet extends Model {
  get $attributes() {
    return [
      'id',
      'role_id',
      'title',
      'description',
      'file',
      'created_at',
      'updated_at',
      'role',
    ]
  }

  get fileURL() {
    return this.file && generateURL(this.file)
  }
}
