import Model from '@fahrirusliyadi/vuejs-model'
import Role from './Role'
import { generateImageURL } from '~/utils/storage'
import { generateAbbr } from '~/utils/string'

export default class User extends Model {
  get $attributes() {
    return [
      'id',
      'role_id',
      'name',
      'email',
      'avatar',
      'phone',
      'address',
      'dob',
      'age',
      'school',
      'had_menstruation',
      'score',
      'created_at',
      'updated_at',
      'role',
      'provider',
      'is_new_password',
      'has_survey',
      'is_max_comment',
      'instagram',
      'is_subscribe',
    ]
  }

  get abbr() {
    return generateAbbr(this.name)
  }

  get hadMenstruationText() {
    return this.had_menstruation === null
      ? null
      : this.had_menstruation
      ? 'Pernah'
      : 'Belum pernah'
  }

  get isGirl() {
    return this.role.code === Role.ROLE_GIRL
  }

  getAvatarURL() {
    return generateImageURL(this.avatar)
  }
}
