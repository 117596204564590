//
//
//
//
//
//

export default {
  name: 'FloatingGachaIcon',
  data() {
    return {
      user: {},
    }
  },
  watch: {
    '$auth.user': {
      handler(user) {
        if (user) this.user = user.toJSON()
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.$auth.user) {
      this.user = this.$auth.user.toJSON()
    }
  },
}
