var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[(_vm.$auth.isLoggedIn)?_c('form',{staticClass:"modal-body text-center text-md-left p-md-4",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('h5',{staticClass:"font-size-24 font-size-md-32 line-height-28 line-height-md-40 font-weight-normal mb-10 mb-md-1"},[_vm._v("\n          "+_vm._s(_vm.isNewPassword ? 'Buat Password' : 'Ganti Password')+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"font-size-md-14 line-height-19 line-height-md-24 mb-4"},[_vm._v("\n          "+_vm._s(_vm.isNewPassword ? 'Buat' : 'Kamu bisa ganti')+" password untuk\n          tetap menjaga akun kamu aman\n        ")]),_vm._v(" "),(!_vm.isNewPassword)?_c('div',{staticClass:"form-group text-left mb-3"},[_c('label',{staticClass:"label-responsive",attrs:{"for":("password_old-" + _vm._uid)}},[_vm._v("\n            Password Lama\n          ")]),_vm._v(" "),_c('FormFieldPassword',{class:{ 'is-invalid': _vm.errors.password_old },attrs:{"attrs":{
              id: ("password_old-" + _vm._uid),
              class:
                'form-control-responsive form-control-outline font-weight-normal pl-3',
              placeholder: 'Masukan password lama kamu',
              required: true,
            }},model:{value:(_vm.input.password_old),callback:function ($$v) {_vm.$set(_vm.input, "password_old", $$v)},expression:"input.password_old"}}),_vm._v(" "),(_vm.errors.password_old)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n            "+_vm._s(_vm.errors.password_old[0])+"\n          ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group text-left mb-3"},[_c('label',{staticClass:"label-responsive",attrs:{"for":("password-" + _vm._uid)}},[_vm._v("\n            Password Baru\n          ")]),_vm._v(" "),_c('FormFieldPassword',{class:{ 'is-invalid': _vm.errors.password },attrs:{"attrs":{
              id: ("password-" + _vm._uid),
              class:
                'form-control-responsive form-control-outline font-weight-normal pl-3',
              placeholder: 'Masukan password baru kamu',
              required: true,
            }},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}}),_vm._v(" "),(_vm.errors.password)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n            "+_vm._s(_vm.errors.password[0])+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-md-flex align-items-center"},[_c('div',{staticClass:"mb-3 mb-md-0 mr-md-64"},[_c('button',{staticClass:"btn btn-responsive btn-primary btn-gradient",attrs:{"disabled":_vm.isSubmitting}},[_vm._v("\n              "+_vm._s(_vm.isNewPassword ? 'Buat Password' : 'Ganti Password')+"\n              "),(_vm.isSubmitting)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-2"}):_vm._e()])]),_vm._v(" "),_vm._m(0)])]):_vm._e()])]),_vm._v(" "),_c('UserFormPasswordModalSuccess',{ref:"modalSuccess"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"font-weight-medium text-primary text-decoration-none",attrs:{"href":"#","data-dismiss":"modal"}},[_vm._v("\n              Batal\n            ")])])}]

export { render, staticRenderFns }