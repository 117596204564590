//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import hasWindow from '~/mixins/hasWindow'

export default {
  mixins: [hasWindow],
  props: {
    isModal: Boolean,
    modalHandleRegister: Function,
    hideModal: Function,
  },
  data() {
    return { errors: {}, input: {}, isSubmitting: false }
  },
  methods: {
    async handleSubmit() {
      try {
        this.errors = {}
        this.isSubmitting = true

        await this.$auth.logIn(this.input)
      } catch (error) {
        this.errors = get(error, 'response.data.errors', {})
        this.$alert.requestError(error)
      } finally {
        this.isSubmitting = false
        this.hideModal()
      }
    },
    handleSocialLogin(type) {
      const redirectTo =
        this.$route.query.redirectTo || window.location.pathname
      localStorage.setItem('redirectTo', redirectTo)
      this.$auth.loginWithSocial(type)
    },
    handleForgotPassword() {
      this.hideModal()
      this.$router.push('/auth/lupa-password')
    },
  },
}
