import { render, staticRenderFns } from "./myth-fact-quiz.vue?vue&type=template&id=b4918f60&scoped=true&"
import script from "./myth-fact-quiz.vue?vue&type=script&lang=js&"
export * from "./myth-fact-quiz.vue?vue&type=script&lang=js&"
import style0 from "./myth-fact-quiz.vue?vue&type=style&index=0&id=b4918f60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4918f60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDesktop: require('/var/www/charm-app/components/NavbarDesktop.vue').default,NavbarMobile: require('/var/www/charm-app/components/NavbarMobile.vue').default,NavbarMobileDrawer: require('/var/www/charm-app/components/NavbarMobileDrawer.vue').default,AlertListModal: require('/var/www/charm-app/components/alert/AlertListModal.vue').default})
