import Model from '@fahrirusliyadi/vuejs-model'

export default class Role extends Model {
  static get ROLE_ADMIN() {
    return 'admin'
  }

  static get ROLE_GIRL() {
    return 'girl'
  }

  static get ROLE_PARENT() {
    return 'parent'
  }

  get $attributes() {
    return ['id', 'code', 'name', 'created_at', 'updated_at']
  }
}
