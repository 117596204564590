//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconClose from '../../assets/svg/close.svg?inline'
import hasWindow from '~/mixins/hasWindow'
import BootstrapModal from '~/components/bootstrap/BootstrapModal'

export default {
  components: { IconClose },
  extends: BootstrapModal,
  mixins: [hasWindow],

  data() {
    return {
      imageUrl: '/images/gold-points/swap-success-logo.png',
    }
  },

  mounted() {
    this.$el.addEventListener(
      'app-hidden.bs.modal',
      () => this.$modalSwapGoldSuccess.hide(),
      {
        once: true,
      }
    )
    this.modal.show()
  },

  methods: {
    handleClose() {
      this.modal.hide()
    },
    handleBack() {
      this.modal.hide()
      this.$router.replace('/artikel')
    },
  },
}
