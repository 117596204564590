import Model from '@fahrirusliyadi/vuejs-model'

/* eslint-disable accessor-pairs */

export default class Quiz extends Model {
  static get CODE_MYTH_FACT() {
    return 'myth_fact'
  }

  static get CODE_PRETEST() {
    return 'pretest'
  }

  static get CODE_POSTTEST() {
    return 'posttest'
  }

  get $attributes() {
    return [
      'id',
      'code',
      'title',
      'created_at',
      'updated_at',
      'questions_count',
      'user_point',
    ]
  }
}
