//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateImageURL } from '~/utils/storage'
import { generateAbbr } from '~/utils/string'

export default {
  props: {
    userProvider: { type: String, default: null },
    avatar: { type: String, default: null },
    name: { type: String, default: null },
    fontSize: { type: Number, default: 32 },
    size: { type: Number, default: 80 },
    isValidPhoto: { type: Boolean, default: false },
  },
  data() {
    return {
      realIsValidPhoto: this.isValidPhoto,
    }
  },
  mounted() {
    this.detectImageUrl()
  },
  methods: {
    generateAbbr,
    generateImageURL,
    detectImageUrl() {
      const path = this.avatar ? this.avatar.split('/') : ''
      const protocol = path[0]
      const yearNow = new Date().getFullYear()
      if (protocol === yearNow.toString()) {
        this.realIsValidPhoto = true
      }
    },
    generateAvatarURL(size) {
      this.detectImageUrl()
      if (this.realIsValidPhoto) {
        return `${generateImageURL(this.avatar)}?w=${size}&h=${size}&fit=crop`
      } else {
        return `${this.avatar}`
      }
    },
  },
}
