class ModalSurveyFailed {
  constructor(context) {
    this._context = context
  }

  get showSurveyFailed() {
    return this?._context.store?.state?.modal?.showSurveyFailed
  }

  show() {
    this._context.store.commit('modal/setShowSurveyFailed', true)
  }

  hide() {
    this._context.store.commit('modal/setShowSurveyFailed', false)
  }
}

export default (context, inject) => {
  const $modalSurveyFailed = new ModalSurveyFailed(context)
  inject('modalSurveyFailed', $modalSurveyFailed)
}
