export default {
  state: () => ({ items: [] }),

  mutations: {
    add: (state, data) => state.items.push(data),
    remove: (state, index) =>
      (state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1),
      ]),
  },
}
