import Model from '@fahrirusliyadi/vuejs-model'

export default class Page extends Model {
  get $attributes() {
    return [
      'id',
      'title',
      'content',
      'content_girl',
      'created_at',
      'updated_at',
    ]
  }
}
