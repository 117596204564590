//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BootstrapModal from '../bootstrap/BootstrapModal'

export default {
  extends: BootstrapModal,

  props: { alert: { type: Object, default: () => ({}) } },

  mounted() {
    this.prevModal = document.querySelector('.modal.show')
    this.modal.show()
  },

  methods: {
    /**
     * Close.
     */
    close() {
      this.$el.addEventListener(
        'app-hidden.bs.modal',
        () => this.$emit('closed'),
        {
          once: true,
        }
      )

      if (
        this.alert.options.showPrevModal &&
        this.prevModal &&
        this.prevModal.ModalSurvey
      ) {
        this.prevModal.ModalSurvey.show()
      }

      this.modal.hide()
    },
  },
}
