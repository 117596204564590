import Model from '@fahrirusliyadi/vuejs-model'
import slugify from '@sindresorhus/slugify'
import dayjs from 'dayjs'
import readingTime from 'reading-time'
import { generateImageURL } from '../utils/storage'
import Category from './Category'
import Role from './Role'
import Tag from './Tag'

/* eslint-disable accessor-pairs */

export default class Article extends Model {
  get $attributes() {
    return [
      'id',
      'category_id',
      'role_id',
      'title',
      'slug',
      'description',
      'image',
      'content',
      'published_at',
      'created_at',
      'updated_at',
      'user_point',
      'category',
      'role',
      'tags',
      'list_category',
      'new_article',
      'total_comments',
      'prev_article',
      'next_article',
    ]
  }

  set category(category) {
    this.$set('category', category && new Category(category))
  }

  set role(role) {
    this.$set('role', role && new Role(role))
  }

  set tags(tags) {
    tags = tags.map((attrs) => new Tag(attrs))
    this.$set('tags', tags)
  }

  get contentText() {
    return this.content.replace(/<\/?[^>]+(>|$)/g, '')
  }

  get imageURL() {
    return generateImageURL(this.image)
  }

  get publishedAt() {
    return dayjs(this.published_at)
  }

  get readingTime() {
    return readingTime(this.content)
  }

  get routePath() {
    return `/artikel/${this.id}/${this.contentSlug}`
  }

  get contentSlug() {
    return this.slug ? this.slug : slugify(this.title.substring(0, 75))
  }
}
