// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/pre-survey/bg.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/pre-survey/bg-2x.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/pre-survey/bg-md.jpg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/img/pre-survey/bg-md-2x.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".app[data-v-7fb895f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-attachment:fixed}@media only screen and (-webkit-min-device-pixel-ratio:2),only screen and (min-device-pixel-ratio:2),only screen and (min-resolution:2dppx),only screen and (min-resolution:192dpi){.app[data-v-7fb895f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}@media(min-width:768px){.app[data-v-7fb895f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}@media only screen and (min-width:768px)and (-webkit-min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-device-pixel-ratio:2),only screen and (min-width:768px)and (min-resolution:2dppx),only screen and (min-width:768px)and (min-resolution:192dpi){.app[data-v-7fb895f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}}.app .logo[data-v-7fb895f6]{width:6.6875rem}@media(min-width:768px){.app .logo[data-v-7fb895f6]{width:10.75rem}}[data-v-7fb895f6] .page-title{font-size:1.75rem;line-height:1.2142857143;font-weight:400;color:#0a0b0d;margin-bottom:1.25rem}@media(min-width:768px){[data-v-7fb895f6] .page-title{font-size:3rem;line-height:1.125;margin-bottom:1rem}}[data-v-7fb895f6] .page-desc{margin-bottom:1.5rem}@media(min-width:768px){[data-v-7fb895f6] .page-desc{font-size:1.25rem;line-height:1.4}}[data-v-7fb895f6] .container{max-width:43.8125rem}[data-v-7fb895f6] input{max-width:33.5rem}", ""]);
// Exports
module.exports = exports;
